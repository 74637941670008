import { isEmpty } from "lodash";
import { bech32 } from "bech32";

export const URL_REGEX =
  /^(?:(?:https?:\/\/)?(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(?:\/[^\s]*)?)|(?:ipfs:\/\/[a-f0-9]+(?:\/[a-zA-Z0-9_]+)*)$|^$/;

const MAINNET_BYRON_REGEX = /^(addr1)[A-Za-z0-9]{58}$/; // Byron addresses
const MAINNET_SHELLEY_REGEX = /^(addr1)[A-Za-z0-9]{98}$/; // Shelley addresses
const MAINNET_STAKE_REGEX = /^(stake1)[A-Za-z0-9]{53}$/; // Stake addresses

const TESTNET_BYRON_REGEX = /^(addr_test1)[A-Za-z0-9]{58}$/; // Byron addresses (testnet)
const TESTNET_SHELLEY_REGEX = /^(addr_test1)[A-Za-z0-9]{98}$/; // Shelley addresses (testnet)
const TESTNET_STAKE_REGEX = /^(stake_test1)[A-Za-z0-9]{53}$/; // Stake addresses (testnet)

export function isValidReceivingAddress(address: string) {
  if (isEmpty(address)) {
    return true;
  }
  return (
    MAINNET_BYRON_REGEX.test(address) ||
    MAINNET_SHELLEY_REGEX.test(address) ||
    MAINNET_STAKE_REGEX.test(address) ||
    TESTNET_BYRON_REGEX.test(address) ||
    TESTNET_SHELLEY_REGEX.test(address) ||
    TESTNET_STAKE_REGEX.test(address)
  );
}

export function isValidURLFormat(str: string) {
  if (str.length === 0) return true;
  return URL_REGEX.test(str);
}


export function decodeDrep(address:string):{credential:string,isScript?:boolean}{
  const drep = decodeAddress(address);
  console.log(drep)
  if(drep.bech32Prefix === 'drep_script'){
    return {
      credential: drep.credential.toString('hex'),
      isScript: true
    }
  }else{
    if(drep.dataHeader.length >1){
      throw new Error("Drep credential contains invalid header: "+address)
    }
    let isScript
    const header=drep.dataHeader.at(0)
    if(header == 0x22){
      isScript = false
    }else if (header == 0x23){
      isScript = true
    }else if ( header){
      throw new Error("Drep credential contains invalid header 0x"+ header.toString(16)+ ": "+address)
    }
    return {
      credential: drep.credential.toString('hex'),
      isScript: isScript
    }
  }

}


export function decodeAddress(address: string): { bech32Prefix: string, dataHeader: Buffer, credential: Buffer } {
  if (!address) return { bech32Prefix: "", dataHeader: Buffer.alloc(0), credential: Buffer.alloc(0) }; // Return empty if address is falsy

  if (isHexValue(address)) {
    // Handle the case where the address is hex-encoded (you can tweak this based on your needs)
    const buffer = Buffer.from(address, 'hex');
    const dataHeader = buffer.subarray(0, buffer.length - 28);
    const credential = buffer.subarray(buffer.length - 28);
    return { bech32Prefix: "hex", dataHeader, credential };
  } else {
    try {
      // Decode the Bech32 address
      const decoded = bech32.decode(address);
      const data = bech32.fromWords(decoded.words);
      const buffer = Buffer.from(data);

      // Split the buffer into header and credential (last 28 bytes for credential)
      const dataHeader = buffer.subarray(0, buffer.length - 28);
      const credential = buffer.subarray(buffer.length - 28);

      return {
        bech32Prefix: decoded.prefix,  // Extract prefix from the Bech32 decoding result
        dataHeader,
        credential
      };

    } catch (e: any) {
      throw new Error("Data is not hex or bech32: " + address);
    }
  }
}


export function isHexValue(value:string):boolean{
  const hexRegex = /^(?:[0-9a-fA-F]{56}|[0-9a-fA-F]{58})$/;
  return hexRegex.test(value);
}

export function validateHash(value:string) {
  const regex = /^[a-f0-9A-F]{64}$/
  if (value.includes('#')){
    return regex.test(value.slice(0,-2))
  }else return regex.test(value)
}

export function fromHex(prefix: string, hex: string) {
  return bech32.encode(prefix, bech32.toWords(Buffer.from(hex, "hex")));
}

export function validateAddress(value: string): boolean {
  if (isHexValue(value)){
    return value.length === 56 || value.length === 58
  }
  return false
}